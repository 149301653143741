.contacts {
  padding: 0.4rem 1.2rem;
  position: fixed;
  bottom: 0;
  width: 100%;

  display: flex;
  flex-direction: row;
  justify-content: space-between;

  box-shadow: 5px 50px 50px rgba(0, 0, 0, 0.9);

  background-color: rgba(237, 237, 237, 0.7);
}

.contact-fragment {
  display: flex;
  flex-direction: row;
}

.contact-info {
  margin: 0 1rem;
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 620px) {
  .contact-info span {
    display: none;
  }
}
