.services-preview {
  display: flex;
  overflow: hidden;

  flex-wrap: wrap;

  flex-direction: row;
  justify-content: center;
}

.service-preview {
  display: flex;
  flex-direction: column;

  justify-content: space-around;

  margin: 0.8rem;

  padding: 0.6rem;

  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.075);

  border-radius: 11px;

  flex: 1 1 0px;

  max-width: 25%;
  min-width: 25rem;

  height: 40rem;

  transition: 0.3s all;
}

.service-preview:hover {
  color: rgb(192, 75, 75);

  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);

  cursor: pointer;

  scale: 1.02;
}

.service-preview-title {
  font-size: 2.4rem;
}

.img-container {
  width: 100%;
  height: 25rem;
  border-radius: 7px;
  overflow: hidden;
}

.service-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media only screen and (max-width: 610px) {
  .service-preview {
    min-width: 35rem;
  }
}
