.image-popup-container {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(30, 30, 30, 0.7);
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);
  top: 0;
  left: 0;

  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.image {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 95%;
  max-height: 90%;
  min-width: 30%;
  border-radius: 7px;
}
.close-popup {
  position: absolute;
  height: 6rem;
  width: 6rem;
  margin: 2.4rem;

  top: 0;
  right: 0;
  color: #eee;
}

.close-popup:hover {
  cursor: pointer;
}
