* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Open Sans", sans-serif;
}

html {
  font-size: 62.5%;

  background-color: rgb(230, 230, 230);
  color: #333;
  scroll-behavior: smooth;
  min-height: 100% !important;
  height: 100%;
  line-height: 1.3;
  word-spacing: 1px;
}

body {
  box-sizing: border-box;
  width: 100%;
}

#root {
  min-height: 100vh;
  font-size: 2rem;
}

h1,
h2,
h3,
h4 {
  font-family: "Oswald";
}

a {
  text-decoration: none;
  color: inherit;
}

.icon {
  width: 25px;
  height: 25px;
  margin: 0 0.2rem;
}

.page-container {
  margin: 5rem auto;
  padding-bottom: 5rem;
  width: 80%;
}

.page-title {
  text-align: center;
  margin: 6rem 0;
}

p {
  margin: 1rem 0;
  font-size: 2rem;
}

.invalid-input {
  border-color: rgb(192, 75, 75) !important;
}

.invalid-message {
  margin-top: 0;
  color: rgb(192, 75, 75);
}

.advantage {
  margin: 1.2rem 0;

  display: grid;
  grid-template-columns: min-content 1fr;
  align-items: center;
  gap: 1rem;
}
.services-title {
  text-align: center;
  margin: 2.4rem 0;

  text-transform: uppercase;
}

.title {
  font-weight: 500;
}

@media only screen and (max-width: 1020px) {
  p {
    margin: 0.6rem 0;
  }

  .page-container {
    width: 98%;
  }

  .page-title {
    font-size: 3rem;
  }

  .advantage {
    margin: 0.2rem 0;
  }

  .icon {
    width: 16px;
    height: 16px;
    margin-right: 0;
  }
}

@media only screen and (max-width: 410px) {
  .invalid-message {
    font-size: 1.5rem;
  }
}
