nav {
  background-color: #333;

  padding: 1.2rem;

  width: 100%;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);

  color: #eee;
}
.main-navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 2.6rem;
}
.nav-links {
  text-align: end;
  display: flex;
}

.nav-link {
  margin: 1.2rem;
  font-family: "Oswald";
  font-weight: 400;
}

.main-links {
  height: 35px;
  display: flex;
  align-items: center;
}

.icon-menu {
  width: 35px;
  height: 35px;

  cursor: pointer;

  transition: 0.3s all;

  display: none;
}

.icon-menu:hover {
  scale: 1.2;
}
.menu-min {
  position: fixed;
  width: 100%;
  height: 100vh;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: rgba(102, 102, 102, 0.6);
  backdrop-filter: blur(5px);

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  font-size: 3rem;
}

.menu-min.open {
  display: flex;
}

.menu-min.closed {
  display: none;
}

.close-menu {
  position: absolute;
  height: 6rem;
  width: 6rem;
  margin: 2.4rem;
  top: 0;
  right: 0;
  cursor: pointer;
}

.nav-logo-container {
  margin-left: 1.4rem;
  width: min-content;
  height: 4rem;

  border-radius: 5px;
  overflow: hidden;
}

.nav-logo {
  display: block;
  background-image: url(../img/logo.webp);
  opacity: 0.9;

  width: 18rem;
  height: 100%;

  background-size: cover;
  background-position: 50% 50%;

  transition: 0.3s all;
}

@media only screen and (max-width: 1200px) {
  .main-links {
    display: none;
  }

  .icon-menu {
    display: block;
  }
}
