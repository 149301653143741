.hero {
  background-image: linear-gradient(
      rgba(50, 50, 50, 0.2),
      rgba(50, 50, 50, 0.2)
    ),
    url("/src/img/home-hero.webp");
  min-width: 80%;
  min-height: 50rem;
  background-size: cover;
  background-position: 50% 45%;
  margin: 0 auto;
  border-radius: 11px;

  color: #eee;

  display: flex;
  justify-content: center;

  position: relative;

  text-align: center;
}

.hero-title {
  position: absolute;

  font-size: 14rem;
  width: 100%;
  font-style: italic;

  color: rgba(237, 237, 237, 0.8);

  top: 40%;
  left: 50%;

  transform: translate(-50%, -50%);
  cursor: default;
}

.hero-text {
  position: absolute;

  font-size: 5rem;
  color: rgba(237, 237, 237, 0.8);

  top: 80%;
  left: 50%;

  transform: translate(-50%, -50%);

  line-height: 5rem;

  transition: all 0.3s;
}

.hero-text span {
  font-style: italic;
}

.hero-text:hover {
  color: rgb(192, 75, 75);
  font-size: 5.2rem;
}

@media only screen and (max-width: 770px) {
  .hero-title {
    font-size: 8rem;
  }
  .hero-text {
    font-size: 3.5rem;
  }
}

@media only screen and (max-width: 532px) {
  .hero-title {
    font-size: 6rem;
  }
  .hero-text {
    font-size: 2.8rem;
  }
}
