.our-target {
  margin: 6rem auto;
  padding: 0.1rem 1rem;
  width: 95%;

  border-radius: 7px;

  max-width: 1240px;

  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.075);
}

@media only screen and (max-width: 950px) {
  .our-target {
    font-size: 1.6rem;
  }
}
