.gallery-wrapper {
  width: 100%;
  height: 100%;
  padding: 1rem;

  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.07);

  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));

  gap: 1rem;
}

.gallery-img-container {
  width: 100%;
  height: 100%;

  border-radius: 7px;

  overflow: hidden;

  transition: 0.3s all;
}

.gallery-img-container:hover {
  cursor: pointer;
  transform: scale(1.05);
}

.img {
  width: 100%;
  height: 100%;

  object-fit: cover;
}

@media only screen and (max-width: 900px) {
  .gallery-wrapper {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}
