.service-content {
  margin: 6rem 0;

  display: grid;
  grid-template-columns: 2fr 3fr;

  width: 100%;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.07);

  border-radius: 9px;
  overflow: hidden;
}

.service-title {
  font-size: 2.8rem;
}

.service-img {
  object-fit: cover;
  padding: 0;

  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.service-text-wrapper {
  padding: 1.2rem;
  padding-bottom: 5rem;
  line-height: 1.3;

  position: relative;

  background-color: rgba(255, 210, 210, 0.1);
}

.service-controls {
  position: absolute;
  bottom: 0;
  width: 100%;

  margin-bottom: 0.4rem;

  display: flex;

  justify-content: space-between;
  align-items: flex-end;
  gap: 1rem;

  font-size: 2.4rem;

  text-shadow: rgba(120, 120, 120, 0.15) 3px 3px;

  padding-left: 1rem;
  padding-right: 2.4rem;
}

.service-reserve {
  display: inline;
  padding: 0.4rem 0.6rem;
  justify-self: end;

  background-color: rgb(121, 51, 50);

  color: rgb(230, 230, 230);

  font-size: 3rem;

  cursor: pointer;

  transition: all 0.2s;

  margin-bottom: 0;

  border-radius: 7px;
}

.service-reserve:hover {
  transform: scale(1.05);
}

.highlighted {
  font-weight: bold;
}

h4 span {
  margin: 2.4rem 0;
}

.sub-advantage {
  padding-left: 1rem;
}

.sub-advantage span {
  font-size: 80%;
}

.cleaning-set-price-info {
  margin-bottom: 1.4rem;
  font-style: italic;
}

.cleaning-set-price-info p {
  font-size: 80%;
  margin: 0.6rem;
}

@media only screen and (max-width: 800px) {
  .service-text-wrapper {
    line-height: 1.2;
    font-size: 1.2rem;
    padding: 0.4rem;
    padding-bottom: 3.4rem;
  }

  .service-text-wrapper p {
    font-size: 1.2rem;
  }

  .sub-advantage {
    margin: 0;
  }

  .sub-advantage .icon {
    width: 1.5rem;
  }
  .service-title {
    font-size: 1.6rem;
  }
  .service-price {
    font-size: 1.2rem;
  }

  .service-reserve {
    font-size: 1.8rem;
  }

  .cleaning-set-price-info p {
    font-size: 80%;
  }
}
