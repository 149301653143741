.reserve-modal {
  position: fixed;
  width: 100%;
  height: 100vh;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: rgba(102, 102, 102, 0.7);
  backdrop-filter: blur(3px);

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  font-size: 3rem;
}

.reserve-form {
  background-color: #ddd;
  padding: 3rem 4rem;
  max-width: 95%;
  overflow: scroll;
  margin: 1rem;
  border-radius: 4px;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  font-size: 2rem;
}

.modal-title {
  text-align: center;
}

.reserve-form::-webkit-scrollbar {
  display: none;
}

.reserve-modal label {
  display: block;
  color: #333;
}

.reserve-modal input,
.reserve-modal select {
  width: 100%;
  padding: 0.6rem;
  font-size: 2rem;
  background-color: #eee;
  border: none;
  border-radius: 3px;
}

.form-controls {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.form-controls button {
  margin-top: 3rem;
  padding: 1rem;
  font-size: 2rem;
  border-radius: 7px;
  cursor: pointer;
}

.submit-btn {
  border: 2px solid rgb(0, 100, 0, 0.5);
}

.reject-btn {
  border: 2px solid rgba(100, 0, 0, 0.5);
}

.form-control-modal {
  margin: 1.4rem;
}

@media only screen and (max-width: 450px) {
  .convenient-time-default select {
    font-size: 1.6rem;
    letter-spacing: -0.6px;
  }

  .convenient-time-default option {
    font-size: 2rem;
  }
}
