.contacts-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
}

.contacts-content {
  padding: 0.4rem;
  max-width: 100%;
  min-width: 23rem;
  width: 45%;
}

.contact-content {
  margin: 1.6rem 0;
}

.contacts-form {
  padding: 1.2rem;

  max-width: 100%;
  min-width: 50%;
  width: 45%;

  box-shadow: -2px -5px 15px rgba(0, 0, 0, 0.04);

  border-radius: 9px;
}

.contact-control {
  display: flex;
  gap: 1rem;

  margin-bottom: 0.4rem;
}

.contact-control a {
  transition: 0.3s all;
}

.contact-control a:hover {
  color: rgb(192, 75, 75);
  transform: scale(1.1);
}

.form-title {
  margin: 1.2rem 0;
  text-align: center;
}

.form-control {
  margin: 1.2rem 0;
}

.form-control label,
.form-control input {
  display: block;
}

.form-control input,
.form-control textarea {
  font-size: 2rem;

  padding: 0.4rem;

  width: 100%;

  border-radius: 5px;

  background-color: rgba(255, 255, 255, 0.5);

  border: 1px solid rgba(0, 0, 0, 0.5);
}

.form-control textarea {
  max-width: 100%;
}

.form-control-submit {
  text-align: center;
}

.form-submit {
  font-family: inherit;

  border: 2px solid rgba(0, 0, 0, 0.5);

  font-size: 2rem;
  padding: 0 0.8rem;

  border-radius: 5px;
  cursor: pointer;

  transition: 0.3s all;
}

.form-submit:hover {
  background-color: rgb(192, 75, 75);
  transform: scale(1.1);

  border: 2px solid rgba(0, 0, 0, 0);
}

@media only screen and (max-width: 800px) {
  .contact-content {
    min-width: 100%;
  }

  .contacts-form {
    min-width: 100%;
  }
}
