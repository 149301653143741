.about {
  font-size: 2.4rem;

  margin: 12rem 0 6rem 0;

  font-weight: 400;
}

.about-title {
  text-transform: uppercase;
  border-bottom: 1px solid #ddd;

  text-transform: uppercase;
}

.about-content {
  margin: 1.2rem 0;

  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
}

.emphasize {
  font-weight: bold;
}

.about-text {
  font-size: 2rem;
  min-width: 34rem;
}

.about-advantages {
  min-width: 38rem;
  font-size: 2rem;
}

@media only screen and (max-width: 950px) {
  .about {
    font-size: 1.3rem;
  }
  .about-content {
    grid-template-columns: 1fr;
    gap: 0;
  }

  .about-text {
    font-size: 1.6rem;
    min-width: 100%;
  }

  .about-advantages {
    font-size: 1.8rem;
    min-width: 100%;
  }
}
